import React from "react";
import Markdown from "react-markdown";
import { Link, useLocation, Navigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import ReactPlayer from "react-player";
import SASLogo from "../assets/sas/sas-logo-black.png";
import PresentationImage from "../assets/sas/image (1) copy.png";
import { ChevronLeft, ChevronUp } from "lucide-react";
const images = {
	SASLogo,
};

const content = `
## Internship Overview

My time at SAS as a Product Design Intern was an incredible experience that allowed me to grow both personally and professionally. I worked on three projects spanning research, design, and development in collaboration with the amazing Filament Design System team. Below are some highlights and insights from my experience.

`;

const SAS = () => {
	const location = useLocation();
	const isAuthenticated = location.state && location.state.isAuthenticated;

	if (!isAuthenticated) {
		return <Navigate to="/" />;
	}

	return (
		<div className="flex flex-col w-full min-h-screen items-center p-4 lg:p-20 gap-y-8 lg:gap-y-20 bg-[#C4DEFD]">
			<div className="flex flex-col lg:flex-row header w-full max-w-[1000px] p-4 lg:p-20 bg-[#4398F9] rounded-3xl shadow-[inset_0_0_10px_4px_rgba(0,0,0,0.1)]">
				<div className="flex flex-col w-full lg:min-w-[400px] lg:max-w-[400px]">
					<Link to="/">
						<div className="flex w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] absolute top-0 left-0 m-4 items-center justify-center text-white cursor-pointer rounded-[50px] hover:rounded-[12px] transition-[border-radius] duration-200 ease-in-out">
							<ChevronLeft size={24} />
						</div>
					</Link>
					<h1 className="text-6xl lg:text-[120px] font-medium mb-4 tracking-tight lg:mt-0 mt-12">
						SAS
					</h1>
					<div className="details grid grid-cols-2 w-full lg:w-[385px] gap-y-2 gap-x-4 text-sm lg:text-base">
						<p className="font-semibold text-right">Created For</p>
						<a
							href="https://www.sas.com/en_us/home.html"
							className="underline"
							target="_blank"
							rel="noopener noreferrer"
						>
							SAS Institute
						</a>
						<p className="font-semibold text-right">Project Duration</p>
						<p>May - August 2024</p>
						<p className="font-semibold text-right">Role</p>
						<p>Product Design Intern</p>
						<p className="font-semibold text-right">Tools Used</p>
						<div className="flex flex-col leading-tight mt-[2px]">
							<p>Figma</p>
							<p>Mural</p>
							<p>Jira</p>
							<p>Confluence</p>
						</div>
						<p className="font-semibold text-right">Skills</p>
						<div className="flex flex-col leading-tight mt-[2px]">
							<p>Design Systems</p>
							<p>Component Design</p>
							<p>UX Writing</p>
							<p>User Research</p>
							<p>Web Design</p>
							<p>Web Development</p>
							<p>LLM System Design</p>
						</div>
					</div>
				</div>
				<div className="flex w-full items-center justify-center mt-8 lg:mt-0">
					<img
						src={PresentationImage}
						alt="hero"
						className="w-full object-contain my-4 lg:my-12 max-h-[300px] lg:max-h-[500px] rounded-2xl mx-auto"
					/>
				</div>
			</div>
			<div className="body w-full pt-8 lg:pt-10 max-w-[1000px] p-4 lg:p-20 bg-[#C4DEFD] rounded-3xl shadow-[inset_0_0_10px_0_rgba(0,0,0,0.1)]">
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						h1: ({ node, ...props }) => (
							<h1 className="text-4xl font-bold my-8 text-center" {...props} />
						),
						h2: ({ node, ...props }) => (
							<h2 className="text-3xl font-bold mb-6 mt-4" {...props} />
						),
						h3: ({ node, ...props }) => (
							<h3 className="text-2xl font-semibold mb-2" {...props} />
						),
						h4: ({ node, ...props }) => (
							<h4
								className="text-2xl font-semibold mb-2 leading-tight"
								{...props}
							/>
						),
						h5: ({ node, ...props }) => (
							<h5 className="text-2xl font-medium mb-2" {...props} />
						),
						h6: ({ node, ...props }) => (
							<h6
								className="text-sm italic text-center mt-[-32px] mb-8"
								{...props}
							/>
						),
						p: ({ node, ...props }) => (
							<p className="mb-4 leading-tight text-neutral-800" {...props} />
						),
						img: ({ node, ...props }) => {
							const imageSrc = images[props.src] || props.src;
							if (imageSrc.endsWith(".mp4")) {
								return (
									<ReactPlayer
										url={imageSrc}
										controls
										className="my-12 rounded-2xl mx-auto"
									/>
								);
							}
							return (
								<img
									src={imageSrc}
									alt={props.alt}
									className="h-full object-contain my-12 max-h-[500px] rounded-2xl mx-auto"
								/>
							);
						},
					}}
				>
					{content}
				</Markdown>
				<div className="presentation-container w-full flex justify-center mt-12">
					<iframe
						style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
						width="100%"
						height="300"
						className="lg:w-[800px] lg:h-[450px]"
						src="https://embed.figma.com/slides/UJSKX2IX9SvR6aFY5gqB1B/SAS-Presentation?node-id=13-146&embed-host=share"
						allowfullscreen
					></iframe>
				</div>
				<p className="text-sm text-center mt-12 italic">
					Note: This presentation may not be shared publicly due to company
					policy. Thank you for your understanding!
				</p>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div
					className="fixed bottom-4 right-4 w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] flex items-center justify-center text-white cursor-pointer rounded-[50px] hover:rounded-[12px] transition-[border-radius] duration-200 ease-in-out"
					onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
				>
					<ChevronUp size={24} />
				</div>
			</div>
		</div>
	);
};

export default SAS;
